@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* user-select: none; */
}

.logobox{
  display: block;
  white-space: nowrap;
  margin: 100px;
}
.logo {
  font-family: "Bungee Shade", cursive;
  font-size: 3em;
  margin-bottom: 0px;
  line-height: 1.1em;
}

.tagline{
  font-family: industry, sans-serif;
  font-weight: 200;
  font-style: italic;
  font-size: 1.3em;
  letter-spacing: 0.2em;
  margin: 0px;
}

iframe {
  /* optional */
  width: 100%;
  height: 100%; 
}

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}



@media only screen and (max-width: 600px) {
  .spotifyplaylist{
    width: 90vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
}

video{
  max-width: unset;
  /* width: unset  !important;
  height: unset !important; */
}